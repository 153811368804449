/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField,
  Typography,
  Divider,
  Chip,
  // FormLabel,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { DropDown, DropdownItem } from './DropDown';
// @ts-ignore
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';

const SELECT_ALL_COLUMN = {
  label: 'Select all',
  value: 'Select all',
  column_name: 'Select all',
};

const columnGetOptionLabel = (option) => {
  return option.column_name ? option.column_name : '';
};

const columnIsOptionEqualToValue = (option, value) => {
  return option.column_name === value.column_name;
};

const datasetGetOptionLabel = (option) => {
  if (option.table_display_name) {
    return `${option.table_display_name} ${
      option?.outputFromPrevNode ? `( ${option?.prevNodeName} )` : ''
    }`;
  }
  return '';
};
const consoleFileGetOptionLabel = (option) => {
  if (option.name) {
    return `${option.name} ${
      option?.outputFromPrevNode ? `( ${option?.prevNodeName} )` : ''
    }`;
  }
  return '';
};

const modelGetOptionLabel = (option) => {
  if (option.model_name) {
    return `${option?.model_name} (${option?.model_technique_label}) ${
      option?.outputFromPrevNode ? `( ${option?.prevNodeName} )` : ''
    }`;
  }
  return '';
};

const modelIsOptionEqualToValue = (option, value) => {
  return option.id === value.id;
};

const consoleFileRenderOption = (optionProps, option, index) => (
  <Box
    display="flex"
    flexGrow={1}
    alignItems="center"
    textOverflow="ellipsis"
    {...optionProps}
    // @ts-ignore
    key={index}
  >
    <Box display="flex" flexGrow={1} textOverflow="ellipsis" width={10} pr={2}>
      <Typography noWrap>{option.name}</Typography>
    </Box>
    {/* for pipeline node */}
    {option.outputFromPrevNode ? (
      <Box display="flex" alignSelf="flex-end">
        <Typography variant="caption">
          Output from previous node : {option?.prevNodeName}
        </Typography>
      </Box>
    ) : (
      ''
    )}
  </Box>
);
const datasetRenderOption = (optionProps, option, index) => (
  <Box
    display="flex"
    flexGrow={1}
    alignItems="center"
    textOverflow="ellipsis"
    {...optionProps}
    // @ts-ignore
    key={index}
  >
    <Box display="flex" flexGrow={1} textOverflow="ellipsis" width={10} pr={2}>
      <Typography noWrap>{option.table_display_name}</Typography>
    </Box>
    {/* for pipeline node */}
    {option.outputFromPrevNode ? (
      <Box display="flex" alignSelf="flex-end">
        <Typography variant="caption">
          Output from previous node : {option?.prevNodeName}
        </Typography>
      </Box>
    ) : (
      ''
    )}
  </Box>
);

const columnRenderOption = (optionProps, option, index) => (
  <Box
    display="flex"
    flexGrow={1}
    alignItems="center"
    textOverflow="ellipsis"
    {...optionProps}
    // @ts-ignore
    key={index}
  >
    <Box display="flex" flexGrow={1} textOverflow="ellipsis" width={10} pr={2}>
      <Typography noWrap>{option.column_name}</Typography>
    </Box>
    <Box display="flex" alignSelf="flex-end">
      <Typography variant="caption">{option?.data_type}</Typography>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginRight: 5, marginLeft: 5 }}
      />
      <Typography variant="caption">{option?.type}</Typography>
    </Box>
  </Box>
);

const modelRenderOption = (optionProps, option, index) => (
  <Box
    display="flex"
    flexGrow={1}
    alignItems="center"
    textOverflow="ellipsis"
    {...optionProps}
    // @ts-ignore
    key={index}
  >
    <Box display="flex" flexGrow={1} textOverflow="ellipsis" width={10} pr={2}>
      <Typography variant="body2">
        {option.model_name
          ? `${option.model_name} (${option.model_technique_label})`
          : ''}
      </Typography>
    </Box>
    {/* for pipeline node */}
    {option.outputFromPrevNode ? (
      <Box display="flex" alignSelf="flex-end">
        <Typography variant="caption">
          Output from previous node : {option?.prevNodeName}
        </Typography>
      </Box>
    ) : (
      ''
    )}
  </Box>
);

const isDatastetOptionEqualToValue = (option, val) => {
  return option?.id === val?.id;
};

/**
 * @function ConsoleArgumentInput
 * @description It renders the console argument input
 * @param {object} props - props object
 * @param {string} type - type of input
 * @param {string} helperText - helper text
 * @param {boolean} error - error
 * @param {array} datasetColumns - dataset columns
 * @param {array} allDatasets - all datasets
 * @param {array} models - all models
 * @param {function} onChange - on change function
 * @param {function} fetchConsoleFileList - to fetch all console files
 * @param {object} activeProject - active project
 * @returns {object} - returns a jsx object
 */

export default function ConsoleArgumentInput({
  type,
  datasetColumns = [],
  onChange,
  helperText,
  error,
  allDatasets = [],
  models = [],
  fetchConsoleFileList = () => {},
  activeProject,
  handleBlur,
  label,
  placeholder,
  ...props
}) {
  const [consoleFilesList, setConsoleFilesList] = useState([]);
  useEffect(() => {
    fetchConsoleFileList(activeProject?.project_id).then((resp) => {
      setConsoleFilesList(resp.data);
    });
  }, [activeProject]);
  const columnsOnChange = useCallback(
    (_event, value) => {
      if (includes(value, SELECT_ALL_COLUMN)) {
        onChange({
          target: {
            value: datasetColumns?.filter((col) => col?.column_name !== 'pk'),
          },
        });
      } else {
        onChange({ target: { value } });
      }
    },
    [onChange]
  );

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        onBlur={handleBlur}
        name="value"
        error={error}
        helperText={helperText}
        label={label}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    [error, helperText, label, placeholder]
  );

  // const modelRenderInput = useCallback(
  //   (params) => (
  //     <TextField
  //       {...params}
  //       onBlur={handleBlur}
  //       name="value"
  //       error={error}
  //       helperText={helperText}
  //       label={label}
  //       placeholder={placeholder}
  //       InputLabelProps={{
  //         shrink: true,
  //       }}
  //     />
  //   ),
  //   [helperText, error, label, placeholder]
  // );

  // Column, String, Number, Boolean, List
  switch (type) {
    case 'Int':
    case 'Float':
      return (
        <TextField
          onChange={onChange}
          name="value"
          onBlur={handleBlur}
          helperText={helperText}
          error={error}
          type="number"
          label={label}
          placeholder={placeholder}
          {...props}
        />
      );
    case 'String':
      return (
        <TextField
          onChange={onChange}
          name="value"
          onBlur={handleBlur}
          helperText={helperText}
          error={error}
          label={label}
          placeholder={placeholder}
          {...props}
        />
      );
    case 'List':
      return (
        <TextField
          onChange={onChange}
          name="value"
          onBlur={handleBlur}
          helperText={helperText}
          error={error}
          label={label}
          placeholder={placeholder}
          {...props}
        />
      );
    case 'Boolean':
      return (
        <Box mt={2}>
          <DropDown
            label={label}
            name="value"
            helperText={helperText}
            error={error}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={handleBlur}
            labelSx={props?.sx?.['.MuiInputLabel-root']}
            {...props}
          >
            <DropdownItem value="true">True</DropdownItem>
            <DropdownItem value="false">False</DropdownItem>
          </DropDown>
        </Box>
      );
    case 'Column':
      return (
        <Autocomplete
          limitTags={2}
          options={
            datasetColumns.length
              ? [
                  SELECT_ALL_COLUMN,
                  ...datasetColumns.filter((col) => col?.column_name !== 'pk'),
                ]
              : []
          }
          multiple
          popupIcon={<DropdownArrow />}
          disableCloseOnSelect
          getOptionLabel={columnGetOptionLabel}
          isOptionEqualToValue={columnIsOptionEqualToValue}
          getOptionDisabled={(option) =>
            option?.column_name === 'No column present'
          }
          renderInput={renderInput}
          renderOption={columnRenderOption}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
              >
                <Chip
                  key={JSON.stringify(option)}
                  color="primary"
                  label={
                    option?.column_name?.length > 10
                      ? `${option?.column_name.slice(0, 8)}..`
                      : option?.column_name
                  }
                  {...getTagProps({ index })}
                  size="small"
                />
              </Box>
            ))
          }
          // @ts-ignore
          onChange={columnsOnChange}
          {...props}
          value={props?.value && Array.isArray(props.value) ? props.value : []}
          noOptionsText="No dataset variables available"
        />
      );
    case 'Dataset':
      return (
        <Autocomplete
          limitTags={2}
          popupIcon={<DropdownArrow />}
          // @ts-ignore
          onChange={(event, value) =>
            onChange({
              target: {
                value: value || '',
              },
            })
          }
          disableClearable={['', undefined].includes(
            props?.value?.table_display_name
          )}
          isOptionEqualToValue={isDatastetOptionEqualToValue}
          options={[...allDatasets.filter((data) => data?.published === true)]}
          getOptionLabel={datasetGetOptionLabel}
          renderOption={datasetRenderOption}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={option.id}
                label={option.Name}
                {...getTagProps({ index })}
                size="small"
              />
            ))
          }
          noOptionsText="No dataset available."
          renderInput={renderInput}
          {...props}
          value={props?.value ? props?.value : {}}
          // value={props?.value && Array.isArray(props.value) ? props.value : []}
        />
      );
    case 'Model':
      return (
        <Autocomplete
          limitTags={2}
          popupIcon={<DropdownArrow />}
          // @ts-ignore
          onChange={(event, value) => onChange({ target: { value } })}
          options={models}
          disableClearable={['', undefined].includes(props?.value?.model_name)}
          getOptionLabel={modelGetOptionLabel}
          isOptionEqualToValue={modelIsOptionEqualToValue}
          renderOption={modelRenderOption}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={option.id}
                label={option.model_name}
                {...getTagProps({ index })}
                size="small"
              />
            ))
          }
          noOptionsText="No model available."
          renderInput={renderInput}
          {...props}
          value={props?.value ? props?.value : {}}
          // value={props?.value && Array.isArray(props.value) ? props.value : []}
        />
      );
    case 'ConsoleFiles':
      return (
        <Autocomplete
          limitTags={2}
          popupIcon={<DropdownArrow />}
          // @ts-ignore
          onChange={(event, value) =>
            onChange({
              target: {
                value: value || '',
              },
            })
          }
          disableClearable={['', undefined].includes(props?.value?.name)}
          options={consoleFilesList}
          getOptionLabel={consoleFileGetOptionLabel}
          renderOption={consoleFileRenderOption}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                {...getTagProps({ index })}
                size="small"
              />
            ))
          }
          renderInput={renderInput}
          {...props}
          value={props?.value ? props?.value : {}}
          // value={props?.value && Array.isArray(props.value) ? props.value : []}
          noOptionsText="No console files available"
        />
      );
    default:
      return (
        <TextField
          onChange={onChange}
          name="value"
          onBlur={handleBlur}
          error={error}
          helperText={helperText}
          placeholder={placeholder || 'Enter value'}
          label={label}
          {...props}
        />
      );
  }
}

ConsoleArgumentInput.defaultProps = {
  datasetColumns: [],
  label: '',
  placeholder: '',
  sx: {},
};
ConsoleArgumentInput.propTypes = {
  type: PropTypes.string.isRequired,
  datasetColumns: PropTypes.oneOfType([PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  allDatasets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  models: PropTypes.oneOfType([PropTypes.array]).isRequired,
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  sx: PropTypes.objectOf(PropTypes.any),
  helperText: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  handleBlur: PropTypes.func.isRequired,
  fetchConsoleFileList: PropTypes.func.isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
